
import { reactive, onMounted, ref } from 'vue'
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import axios from "@/plugin/vue-axios";
import { useRouter, useRoute } from "vue-router";

import ReumTitle from '@/components/ReumLib/layout/ReumTitle.vue'
import ReumTextBox from '@/components/ReumLib/UI/ReumTextBox.vue'


export default {
    name: 'Home',
    components: {
        ReumTitle,
        ReumTextBox
    },
    props: {
        id: {
            type: [Number, String],
            default: -1,
        },
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const toast = useToast();
        const store = useStore();
        const gelImage = ref(null);
        const gelImagePreview = ref(null);
        const xlsFile = ref(null);

        const options = {
            status: [
                {value: "DNA Prep 완료", label:"DNA Prep 완료"},
                {value: "DNA 재추출", label:"DNA 재추출"},
                {value: " 검체 재요청", label:" 검체 재요청"},
            ],
            status_qc: [
                {value: "", label:"미입력"},
                {value: "PASS", label:"PASS", color:"#00F"},
                {value: "FAIL", label:"FAIL", color:"#F00"},
            ],
            gender : [
                {value: "", label:"미입력"},
                {value: "M", label:"남성"},
                {value: "F", label:"여성"},
            ]
        }

        const sample = reactive({
            files: null,
            list: [{id_after:null, id_before:"", sn:"", sn_after:"", well_no: "",  dna_conc:"", qc:"", status:""}],
            delData : () => {
                if(confirm("샘플 정보를 삭제하시겠습니까?")) {
                    let params = {
                        id: comp.id,
                        dna_batch_id: comp.plateinfo.dna_batch_id,
                    }
                    axios.post("/api/DNAPrep/delPlate", params).then((res) => {
                        if(res.data.err == 0) {
                            toast.success("삭제되었습니다");
                            router.push({path: "/DNAPrep/list"});

                        } else {
                            toast.error(res.data.err_msg);
                        }
                    });
                }

            },
            excelcheck : () => {
                let formData = new FormData();
                if(xlsFile.value.files.length<1) {
                    toast.error("파일을 첨부해주세요.");
                    return;
                }
                formData.append("nfile", xlsFile.value.files[0]);

                axios.post("/api/common/xlsxReader", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    if(res.data.err == 0) {
                        console.info(res.data);
                        if(res.data.data[0][0]!="ID" || res.data.data[0][1]!="SN. Before" || res.data.data[0][2]!="SN. After") {
                            toast.error("양식이 일치하지 않습니다.");
                            return;
                        }
                        for(let i=1;i<res.data.data.length;i++) {
                            if(sample.list[i-1].id == res.data.data[i][0] && sample.list[i-1].well_no == res.data.data[i][4]) {
                                sample.list[i-1].sn = res.data.data[i][1];
                                sample.list[i-1].sn_after = res.data.data[i][2];
                                sample.list[i-1].exp_date = res.data.data[i][3];
                                sample.list[i-1].dna_conc = res.data.data[i][5];
                                sample.list[i-1].storage_location = res.data.data[i][8];
                                sample.list[i-1].qc = res.data.data[i][10];
                                sample.list[i-1].status = res.data.data[i][11];
                                sample.list[i-1].memo = res.data.data[i][12];
                            }
                        }
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });

            },
            excelupload: () => {
                xlsFile.value.click();
            },
            getExcelSample: () => {
                location.href="/rest/api/common/getExcelSample?sample=DNAPrep&id="+comp.id;
            },
            exceldown : () => {
                let schema = [
                    {key: "id", label: "ID", width: 10},
                    {key: "sn_before", label: "SN. Before", width: 30},
                    {key: "sn_after", label: "SN. After", width: 30},
                    {key: "exp_date", label: "실험일", width: 15},
                    {key: "well_no", label: "Well No.", width: 10},
                    {key: "dna_conc", label: 'DNA Conc.\n(ng/ul)', width: 15},
                    {key: "gender", label: "성별", width: 10},
                    {key: "date_of_collection", label: "회수일", width: 15},
                    {key: "storage_location", label: "보관위치", width: 30},
                    {key: "tat", label: "TAT", width: 10},
                    {key: "qc", label: "QC", width: 10},
                    {key: "status", label: "상태", width: 20},
                    {key: "memo", label: "비고", width: 30},
                ];
                let data = [];
                for(let i=0;i<sample.list.length;i++) {
                    data.push({
                        "id" : sample.list[i].id,
                        "sn_before" : sample.list[i].sn,
                        "sn_after" : sample.list[i].sn_after,
                        "well_no" : sample.list[i].well_no,
                        "dna_conc" : sample.list[i].dna_conc,
                        "gender" : sample.list[i].gender,
                        "date_of_collection" : sample.list[i].date_of_collection,
                        "storage_location" : sample.list[i].storage_location,
                        "qc" : sample.list[i].qc,
                        "tat" : sample.list[i].tat,
                        "exp_date" : sample.list[i].exp_date,
                        "status" : sample.list[i].status,
                        "memo" : sample.list[i].memo,
                    });
                }
                let params = {
                    info: {dna_batch_id : comp.plateinfo.dna_batch_id},
                    schema: schema,
                    data: data,
                }
                axios({url:"/api/common/toXlsx", method:'POST', responseType: 'blob', data: params}).then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let today = new Date();
                    link.setAttribute('download', 'DNA_'+ comp.plateinfo.dna_batch_id +'_'+comp.dateToString(today)+'.xlsx');
                    document.body.appendChild(link);
                    link.click();
                });
            },
            changeBatchID : () => {
                if(comp.id==0) {
                    comp.setPlateInfo();
                    return;
                }
                let params = {
                    id: comp.id,
                    dna_batch_id: comp.plateinfo.dna_batch_id,
                    note: comp.plateinfo.note,
                }
                axios.post("/api/DNAPrep/changeBatchID", params).then((res) => {
                    if(res.data.err == 0) {
                        toast.success("DNA Batch ID가 변경되었습니다.");
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            initData: (data) => {
                data.exp_date="";
                data.sn="";
                data.sn_after="";
                data.dna_conc="";
                data.gender="";
                data.date_of_collection="";
                data.storage_location="";
                data.tat="";
                data.qc="";
                data.status="";
                data.memo="";
                data.id_member=store.state.id_member;
                return data;
            },
            saveAll: () => {
                    let params = {
                        id_member: store.state.id_member,
                        infoArray: sample.list
                    }
                    axios.post("/api/DNAPrep/sampleUpdateAll", params).then((res) => {
                        if(res.data.err == 0) {
                            let err_count = 0;
                            let update_count = 0;
                            for(let i = 0; i < res.data.list.length; i++) {
                                console.info(res.data.list[i]);
                                if(res.data.list[i].err == 0) {
                                    sample.list[i] = res.data.list[i].info;
                                    update_count++;
                                } else if(res.data.list[i].err == 203) {
                                    sample.list[i] = sample.initData(sample.list[i]);
                                    update_count++;
                                } else {
                                    err_count++;
                                }
                            }
                            if(err_count>0) {
                                toast.error("문제가 있는 데이터가 "+err_count+"건 있습니다.("+update_count+"건 업데이트)");
                            } else {
                                toast.success(update_count+"건의 데이터가 업데이트 되었습니다.");
                            }
                        }
                    });
            },
            moveNext: (el, id, do_save, data) => {
                if(do_save) {
                    let params = {
                        id_member: store.state.id_member,
                        barcode: data.sn,
                        info: data
                    }
                    axios.post("/api/DNAPrep/sampleUpdate", params).then((res) => {
                        if(res.data.err == 0) {
                            let without = ["id", "id_dna_prep_batch", "well_no"]
                            Object.keys(res.data.info).forEach(key => {
                                if(key in data && !without.includes(key)) {
                                    data[key] = (res.data.info[key]===null?"":res.data.info[key]);
                                }
                            });
                            toast.success("샘플 정보가 업데이트 되었습니다.", {timeout: 700});
                        } else {
                            if(res.data.err==203) {
                                data = sample.initData(data);
                            }
                            toast.error(res.data.err_msg);
                        }
                    });
                }
                if(typeof el.srcElement.dataset.next=='undefined' || el.srcElement.dataset.next==null) {
                    return;
                }
                let nextElementId = id + el.srcElement.dataset.next;
                let check = document.getElementById(nextElementId);
                if(check!==null) {
                    check.focus();
                }
            },
            movePrev: (el, id, do_save) => {
                if(do_save) {
                    console.info("Save");
                }
                if(typeof el.srcElement.dataset.prev=='undefined' || el.srcElement.dataset.prev==null || el.srcElement.dataset.prev=="") {
                    return;
                }
                let nextElementId = id + el.srcElement.dataset.prev;
                let check = document.getElementById(nextElementId);
                if(check!==null) {
                    check.focus();
                }
            },
        });

        const imageViewer = reactive({
            show: false,
            url: "",
            name: "",
            doShow: (pic) => {
                imageViewer.name = pic.file_name;
                imageViewer.url = pic.file_path;
                imageViewer.show = true;
            },
            doHide: () => {
                imageViewer.show = false;
            },
            doRemove: (pic) => {
                if(confirm("파일을 삭제하시겠습니까?")) {
                    let params = {
                        id_dna_prep_batch: comp.id,
                        id_dna_prep_batch_files: pic.id,
                        id_member: store.state.id_member
                    }
                    axios.post("/api/DNAPrep/fileDelete", params).then((res) => {
                        if(res.data.err == 0) {
                            for(let i=0;i<sample.files.length;i++) {
                                if(sample.files[i].id == pic.id) {
                                    sample.files.splice(i,1);
                                }
                            }
                        } else {
                            toast.error(res.data.err_msg);
                        }
                    });
                }
            }

        });

        const comp = reactive({
            plateinfo: {
                id: "",
                dna_batch_id: "",
                row: "H",
                col: "12"
            },
            imageFiles: [],
            plateSaved: false,
            hasImage: false,
            imageURL: "url(/resources/img/upload.png)",
            id:0,
            is_view: true,
            doDrag: false,
            fileReset: true,
            barcode: "",
            info: null,
            dateToString(pdate) {
                return (pdate == null?"":[pdate.getFullYear(),((pdate.getMonth() + 1)>9 ? '' : '0') + (pdate.getMonth() + 1),(pdate.getDate()>9 ? '' : '0') + pdate.getDate()].join(''));
            },
            dragOn: () => {
                comp.doDrag = true;
            },
            dragOff: () => {
                comp.doDrag = false;
            },
            uploadImage: () => {
                let formData = new FormData();
                formData.append("id", comp.id);
                formData.append("id_member", store.state.id_member);
                for(let i=0;i<gelImage.value.files.length;i++) {
                    formData.append("nfiles[]", gelImage.value.files[i]);
                }

                axios.post("/api/DNAPrep/fileUpload", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    if(res.data.err == 0) {
                        sample.files = res.data.files;
                        for(let i=0;i<sample.files.length;i++) {
                            let ext = sample.files[i].file_path.toUpperCase().substr(-4);
                            let searchText = ".PNG.JPG.JPEG.GIF.BMP";
                            if(searchText.indexOf(ext)>=0) {
                                sample.files[i].file_thumb = sample.files[i].file_path;
                            }
                        }
                        comp.dragOff();
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            setPlateInfo: () => {

                if(comp.plateinfo.dna_batch_id.trim()=="") {
                    toast.error("DNA Batch ID를 입력해주세요.");
                    return;
                }

                comp.plateinfo.row = comp.plateinfo.row.toUpperCase();

                let cnt_row = comp.plateinfo.row.charCodeAt(0) - 'A'.charCodeAt(0);
                let cnt_col = comp.plateinfo.col;
                if(cnt_row<0 || cnt_row>25) {
                    toast.error("Row는 A~Z까지 알파벳으로 입력해주세요.");
                    return;
                }

                let sample_list = [];
                let id_before = null;
                for(let j=0;j<cnt_col;j++) {
                    for(let i=0;i<cnt_row+1;i++) {
                        let well_no = String.fromCharCode(65+i) + (j+1);
                        sample_list.push({id_after:null, id_before:id_before, sn_before:"", sn_after:"", well_no: well_no,  dna_conc:"", qc:"", status:""});
                        id_before = well_no;
                        if(sample_list.length>1) {
                            sample_list[sample_list.length-2].id_after = well_no;
                        }
                    }
                }
                let params = {
                    dna_batch_id: comp.plateinfo.dna_batch_id,
                    row: comp.plateinfo.row,
                    col: comp.plateinfo.col,
                    list: sample_list,
                    note: comp.plateinfo.note
                }
                axios.post("/api/DNAPrep/make", params).then((res) => {
                    if(res.data.err == 0) {
                        comp.plateSaved = true;
                        router.push({path: "/DNAPrep/"+res.data.dna_batch_id});

                        sample.list = [];
                        comp.id = res.data.dna_batch_id;
                        comp.getInfo();

                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            changeQC: () => {
                // if(comp.info.qc=="PASS") {
                //     options.status = [
                //         {value: "", label:"미지정"},
                //         {value: "DNA Prep 완료", label:"DNA Prep 완료"}
                //     ];
                // } else if(comp.info.qc=="FAIL") {
                //     options.status = [
                //         {value: "", label:"미지정"},
                //         {value: "DNA 재추출", label:"DNA 재추출"},
                //         {value: " 검체 재요청", label:" 검체 재요청"},
                //     ];
                // } else {
                //     options.status = [
                //         {value: "DNA Prep 완료", label:"DNA Prep 완료"},
                //         {value: "DNA 재추출", label:"DNA 재추출"},
                //         {value: " 검체 재요청", label:" 검체 재요청"},
                //     ];
                // }
            },
            modify: () => {
                comp.is_view = false;
            },
            delete: () => {
                let params = {
                    id: comp.info.id
                }
                axios.post("/api/DNAPrep/delete", params).then((res) => {
                    if(res.data.err == 0) {
                        router.push({path: "/DNAPrep/list"});
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            goView: () => {
                comp.is_view = true;
                comp.getInfo();
            },
            goList: () => {
                router.push({path: "/DNAPrep/list"});
            },
            getBarcodeInfo: () => {
                let params = {
                    barcode: comp.barcode,
                }
                axios.post("/api/common/barcode", params).then((res) => {
                    if(res.data.err == 0) {
                        comp.info.serial_no = res.data.info.serial_no;
                        comp.info.client = res.data.info.client;
                        comp.info.date_of_collection = res.data.info.date_of_collection;
                        comp.info.gender = res.data.info.gender;
                        comp.info.product = res.data.info.product;
                        comp.info.tat = res.data.info.tat;
                        comp.info.original_serial_no = res.data.info.original_serial_no;
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });
            },
            setInfo: () => {
                let params = {
                    id:comp.id,
                    ...comp.info
                }
                axios.post("/api/DNAPrep/upsert", params).then((res) => {
                    if(res.data.err == 0) {
                        comp.goList();
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });

            },
            getInfo: () => {
                let params = {
                    tmr: new Date(),
                }
                axios.get("/api/DNAPrep/info/"+ Math.round(comp.id), {params : params}).then((res) => {
                    if(res.data.err == 0) {
                        comp.plateinfo = res.data.dna_batch_info;
                        sample.list = res.data.list;
                        sample.files = res.data.files;
                        for(let i=0;i<sample.files.length;i++) {
                            let ext = sample.files[i].file_path.toUpperCase().substr(-4);
                            let searchText = ".PNG.JPG.JPEG.GIF.BMP";
                            if(searchText.indexOf(ext)>=0) {
                                sample.files[i].file_thumb = sample.files[i].file_path;
                            }
                        }
                        comp.plateSaved = true;
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });

            },
        });
        onMounted(() => {
            sample.list = [];
            if(route.params.id=="new") {
                comp.id = 0;
                comp.is_view = false;
                comp.info = {};
            } else {
                comp.id = route.params.id;
                if(comp.id==="") {
                    router.replace({"path":"/DNAPrep/list"});
                } else {
                    comp.getInfo();
                }
            }
        });
        return {
            comp,
            options,
            sample,
            gelImage,
            gelImagePreview,
            imageViewer,
            xlsFile
        };
    },
};
